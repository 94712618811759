import il8n from '@/assets/language/index.js';
const { t } = il8n.global;

export const object = [{
    type: 'select',
    name: t('currencySetNft.name1'),
    prop: 'tenantId',
    isPostApi: 'shopList',
    postURl: '',
    postData: [],
    isPlatform: 1,
    props:{
        value:'id',
        label:'name'
    }
},{
    type: 'select',
    name: t('currencySetNft.name2'),
    prop: 'appId',
    isPostApi: 'getUserappinfoList',
    postURl: '',
    postData: [],
    props:{
        value:'appId',
        label:'appName'
    }
},{
    type: 'select',
    name: t('currencySetNft.text6'),
    prop: 'chain',
    isPostApi: 'getCoinList',
    postURl: '',
    postData: [],
    props:{
        value:'chain',
        label:'chain'
    }
},{
    type: 'select',
    name: t('currencySetNft.text7'),
    prop: 'protocol',
    isPostApi: 'getAgreementList',
    postURl: '',
    postData: [],
    props:{
        value:'protocol',
        label:'protocol'
    }
},{
    type: 'input',
    name: t('otherWallatNft.name1'),
    prop: 'walletName',
},{
    type: 'datetime',
    name: t('currencySetNft.name6'),
    prop: 'createTime',
},{
    type: 'select',
    name: t('currencySetNft.name9'),
    prop: 'status',
    postData: [{ label: t('currencySetNft.name11_1'), value: 1 },{ label: t('currencySetNft.name11_2'), value: 0 }]
},]

export const coin = [{
    type: 'select',
    name: t('currencySetNft.text6'),
    prop: 'chain',
    isPostApi: 'getCoinList',
    postURl: '',
    postData: [],
    props:{
        value:'chain',
        label:'chain'
    }
},{
    type: 'input',
    name: t('currencySetNft.name10'),
    prop: 'coin',
},]
export const addrSearch = [{
    type: 'input',
    name: t('otherWallatNft.name1'),
    prop: 'walletName',
},{
    type: 'input',
    name: t('otherWallatNft.text6'),
    prop: 'walletAddress',
},]