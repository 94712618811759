<template>
    <div>
        <fromSearch :object_='object_' @searchFun='getList' />
        <el-table :data="list" border v-loading='listLoading' height="350">
            <el-table-column prop="selection" :label="$t('currencySetNft.text8')" width="100" >
                <template #default="scope">
                    <el-checkbox size="large" @change="changeFun(scope.row)" v-model='checkItem'/>
                </template>
            </el-table-column>
            <el-table-column prop="coin" :label="$t('currencySet.text1')" width="100" />
            <el-table-column :label="$t('contra.text5')" width="100" >
                <template #default="scope">
                    {{scope.row.chain}} / {{scope.row.protocol}}
                </template>
            </el-table-column>
            <el-table-column prop="walletName" :label="$t('currencySetNft.name4')" width="120" />
            <el-table-column prop="walletAddress" :label="$t('otherWallatNft.text6')" />
            <el-table-column prop="amount" :label="$t('otherWallat.name3')" width="100" />
        </el-table>
        <pageCom :page='page' @sizeChange='sizeChange' @currentChange='currentChange'/>
    </div>
</template>
<script setup>
    import Api_ from '@/api/index'
    import { ref,reactive,defineEmits,defineExpose } from 'vue'
    import pageCom from '@/components/pageCom.vue'
    import { addrSearch } from '@/const/from/nft/hotWallet'
    import fromSearch from '@/components/fromSearch.vue'
    
    const list = ref([])
    const object_ = ref({});object_.value = addrSearch
    const listLoading = ref(false)
    const page = reactive({
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 20 // 每页显示多少条
    })
    const formValue = ref({})
    const checkItem = ref(false)
    const emit = defineEmits(['getCoin1'])
    const changeFun = (e)=>{
        emit('getCoin1',e)
    }
    const getList = (e)=>{
        let obj_ = {
            current: page.currentPage,
            size: page.pageSize,
            ...formValue.value
        }
        if(e && e !== 1){
            obj_ = Object.assign({}, obj_, e)
        }
        listLoading.value = true
        Api_.getAssetswalletinfoList(obj_).then(res=>{
            if(res.code === 0){
                listLoading.value = false
                list.value = res.data.records
                page.total = res.data.total
            }
        })
    }
    const sizeChange = (pageSize)=>{ 
        page.pageSize = pageSize;
        getList(1)
    }
    const currentChange = (currentPage)=>{ 
        page.currentPage = currentPage;
        getList(1)
    }
    const init_ = (e)=>{
        formValue.value = e
        checkItem.value = false
        getList(1)
    }
    defineExpose({init_})
    
</script>

<style lang="scss" scoped>
</style>